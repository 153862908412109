define("@nfn/cannon/components/content-link/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="content-link__content" ...attributes>
    {{yield
      (hash
        cta=(component "arrow-cta" href=@href route=@route query=@query)
        sub=(component "content-link/sub")
      )
    }}
  </div>
  */
  {
    "id": "8KJwwzUK",
    "block": "{\"symbols\":[\"&attrs\",\"@query\",\"@route\",\"@href\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"content-link__content\"],[17,1],[12],[2,\"\\n  \"],[18,5,[[30,[36,1],null,[[\"cta\",\"sub\"],[[30,[36,0],[\"arrow-cta\"],[[\"href\",\"route\",\"query\"],[[32,4],[32,3],[32,2]]]],[30,[36,0],[\"content-link/sub\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/content-link/content.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});