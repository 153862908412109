define("@nfn/cannon/components/bs-form/element/control/plaintext", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <input
    type={{@type}}
    id={{@id}}
    disabled={{@disabled}}
    readonly
    aria-describedby={{@ariaDescribedBy}}
    value={{@value}}
    class="form-control-plaintext"
    ...attributes
  />
  */
  {
    "id": "rqDJljNz",
    "block": "{\"symbols\":[\"@id\",\"@disabled\",\"@ariaDescribedBy\",\"@value\",\"&attrs\",\"@type\"],\"statements\":[[11,\"input\"],[16,1,[32,1]],[16,\"disabled\",[32,2]],[24,\"readonly\",\"\"],[16,\"aria-describedby\",[32,3]],[16,2,[32,4]],[24,0,\"form-control-plaintext\"],[17,5],[16,4,[32,6]],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/bs-form/element/control/plaintext.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});