define("@nfn/cannon/components/site/cannon-brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route={{or @target "index"}} class="navbar-brand" ...attributes>
    <img class="navbar__logo" src="/cannon/images/logo/primary-bk.svg" alt="Nasdaq" />
  </LinkTo>
  */
  {
    "id": "Od8tYndx",
    "block": "{\"symbols\":[\"@target\",\"&attrs\"],\"statements\":[[8,\"link-to\",[[24,0,\"navbar-brand\"],[17,2]],[[\"@route\"],[[30,[36,0],[[32,1],\"index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"img\"],[14,0,\"navbar__logo\"],[14,\"src\",\"/cannon/images/logo/primary-bk.svg\"],[14,\"alt\",\"Nasdaq\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "moduleName": "@nfn/cannon/components/site/cannon-brand.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});