define('ember-cli-rollbar2/services/rollbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function wrapConsole(name) {
    /* eslint-disable no-console */
    return function () {
      if (console && console[name]) {
        var _console;

        (_console = console)[name].apply(_console, arguments);
      }
    };
  }

  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var rollbar = Ember.getOwner(this).lookup('rollbar:main');
      if (rollbar) {
        this.instance = rollbar;
      } else {
        // In this case, forward to the console if it exists
        this.instance = {
          log: wrapConsole('log'),
          debug: wrapConsole('debug'),
          info: wrapConsole('info'),
          warn: wrapConsole('warn'),
          warning: wrapConsole('warn'),
          error: wrapConsole('error'),
          critical: wrapConsole('error'),
          configure: wrapConsole('log')
        };
      }
    },
    log: function log() {
      var _instance;

      return (_instance = this.instance).log.apply(_instance, arguments);
    },
    debug: function debug() {
      var _instance2;

      return (_instance2 = this.instance).debug.apply(_instance2, arguments);
    },
    info: function info() {
      var _instance3;

      return (_instance3 = this.instance).info.apply(_instance3, arguments);
    },
    warn: function warn() {
      var _instance4;

      return (_instance4 = this.instance).warn.apply(_instance4, arguments);
    },
    warning: function warning() {
      var _instance5;

      return (_instance5 = this.instance).warning.apply(_instance5, arguments);
    },
    error: function error() {
      var _instance6;

      return (_instance6 = this.instance).error.apply(_instance6, arguments);
    },
    critical: function critical() {
      var _instance7;

      return (_instance7 = this.instance).critical.apply(_instance7, arguments);
    },
    setPerson: function setPerson(person) {
      return this.instance.configure({ payload: { person: person } });
    }
  });
});