define("@nfn/cannon/helpers/cn-join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.join = join;

  function join(params, _ref) {
    var sep = _ref.sep;
    return params.join(sep);
  }

  var _default = Ember.Helper.helper(join);

  _exports.default = _default;
});