define("ember-table/components/-private/scroll-indicators/component", ["exports", "ember-table/-private/utils/observer", "ember-table/components/-private/scroll-indicators/template"], function (_exports, _observer, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
     Computed property macro that builds the CSS styles (position, height)
     for each horizontal scroll indicator element.
  
     @param {string} side - which side we are computing styles for: `left` or `right`
   */
  var horizontalIndicatorStyle = function horizontalIndicatorStyle(side) {
    return Ember.computed("columnTree.".concat(side, "FixedNodes.@each.width"), 'overflowHeight', 'scrollbarWidth', 'tableHeight', function () {
      var style = []; // left/right position

      var offset = 0;
      var fixedNodes = this.get("columnTree.".concat(side, "FixedNodes"));

      if (!Ember.isEmpty(fixedNodes)) {
        var fixedWidth = fixedNodes.reduce(function (acc, node) {
          return acc + node.get('width');
        }, 0);
        offset += fixedWidth;
      }

      if (side === 'right') {
        var scrollbarWidth = this.get('scrollbarWidth') || 0;
        offset += scrollbarWidth;
      }

      style.push("".concat(side, ":").concat(offset, "px;")); // height

      var overflowHeight = this.get('overflowHeight');

      if (!Ember.isNone(overflowHeight)) {
        var tableHeight = this.get('tableHeight');
        var height = Ember.isNone(tableHeight) ? overflowHeight : Math.min(overflowHeight, tableHeight);
        style.push("height:".concat(height, "px;"));
      }

      return Ember.String.htmlSafe(style.join(''));
    });
  };
  /**
     Computed property macro that builds the CSS styles (position, width)
     for each vertical scroll indicator element.
  
     @param {string} location - which location we are computing styles for: `top` or `bottom`
   */


  var verticalIndicatorStyle = function verticalIndicatorStyle(location) {
    return Ember.computed("columnTree.".concat(location, "FixedNodes.@each.width"), 'overflowHeight', 'overflowWidth', 'tableWidth', 'headerHeight', 'scrollbarHeight', 'visibleFooterHeight', 'footerRatio', function () {
      var style = [];
      var offset = 0; // top/bottom offset

      if (location === 'top') {
        var headerHeight = this.get('headerHeight') || 0;
        offset += headerHeight;
      }

      if (location === 'bottom') {
        var visibleFooterHeight = this.get('visibleFooterHeight') || 0;
        var scrollbarHeight = this.get('scrollbarHeight') || 0;
        var footerRatio = this.get('footerRatio'); // when footer occupies > 50% of the overflow height, we are now
        // scrolling the footer rows, so indicator should jump to table bottom

        if (footerRatio <= 0.5) {
          offset += visibleFooterHeight;
        }

        offset += scrollbarHeight;
      }

      style.push("".concat(location, ":").concat(offset, "px;")); // width

      var tableWidth = this.get('tableWidth');

      if (!Ember.isNone(tableWidth)) {
        var overflowWidth = this.get('overflowWidth');
        var width = Math.min(tableWidth, overflowWidth);
        style.push("width:".concat(width, "px;"));
      }

      return Ember.String.htmlSafe(style.join(''));
    });
  };
  /**
     Computed property macro that builds a boolean to determine whether or not
     to show a scroll indicator in the given position.
  
     @param {string} location - `left`, `right`, `top`, or `bottom`
   */


  var showIndicator = function showIndicator(location) {
    var scrollProp = "scroll".concat(Ember.String.capitalize(location));
    return Ember.computed('enabledIndicators', scrollProp, function () {
      return this.get('enabledIndicators').includes(location) && this.get(scrollProp) > 0;
    });
  };

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
      The API object passed in by the table
       @argument api
      @required
      @type object
    */
    api: null,
    scrollLeft: null,
    scrollRight: null,
    scrollTop: null,
    scrollBottom: null,
    scrollbarWidth: null,
    scrollbarHeight: null,
    overflowHeight: null,
    overflowWidth: null,
    tableHeight: null,
    tableWidth: null,
    headerHeight: null,
    visibleFooterHeight: null,
    footerRatio: null,
    columnTree: Ember.computed.readOnly('api.columnTree'),
    containerWidthAdjustment: Ember.computed.readOnly('api.columnTree.containerWidthAdjustment'),
    scrollIndicators: Ember.computed.readOnly('api.scrollIndicators'),
    tableScrollId: Ember.computed.readOnly('api.tableId'),
    showLeft: showIndicator('left'),
    showRight: showIndicator('right'),
    showTop: showIndicator('top'),
    showBottom: showIndicator('bottom'),
    leftStyle: horizontalIndicatorStyle('left'),
    rightStyle: horizontalIndicatorStyle('right'),
    topStyle: verticalIndicatorStyle('top'),
    bottomStyle: verticalIndicatorStyle('bottom'),
    enabledIndicators: Ember.computed('scrollIndicators', function () {
      switch (this.get('scrollIndicators')) {
        case true:
        case 'all':
          return ['left', 'right', 'top', 'bottom'];

        case 'horizontal':
          return ['left', 'right'];

        case 'vertical':
          return ['top', 'bottom'];

        case false:
        case 'none':
        default:
          return [];
      }
    }),
    _addListeners: function _addListeners() {
      this._isListening = true; // cache static elements for performance

      this._scrollElement = document.getElementById(this.get('tableScrollId'));
      this._tableElement = this._scrollElement.querySelector('table');
      this._headerElement = this._tableElement.querySelector('thead');
      this._onScroll = Ember.run.bind(this, this._updateIndicators);

      this._scrollElement.addEventListener('scroll', this._onScroll);

      this._resizeSensor = new ResizeSensor(this._tableElement, Ember.run.bind(this, this._updateIndicators));
    },
    _removeListeners: function _removeListeners() {
      this._isListening = false;

      this._scrollElement.removeEventListener('scroll', this._onScroll);

      this._resizeSensor.detach();
    },
    _updateIndicators: function _updateIndicators() {
      var el = this._scrollElement;
      var table = this._tableElement;
      var header = this._headerElement;
      var scrollLeft = el.scrollLeft;
      var scrollRight = el.scrollWidth - el.clientWidth - scrollLeft;
      var scrollTop = el.scrollTop;
      var scrollBottom = el.scrollHeight - el.clientHeight - scrollTop;
      var scrollbarWidth = el.offsetWidth - el.clientWidth;
      var scrollbarHeight = el.offsetHeight - el.clientHeight;
      var overflowHeight = el.clientHeight;
      var overflowWidth = el.clientWidth;
      var tableWidth = table ? table.offsetWidth : null;
      var tableHeight = table ? table.offsetHeight : null;
      var headerHeight = header ? header.offsetHeight : null; // part of the footer can be obscured until the table is scrolled to the
      // bottom; see `addon/-private/sticky/table-sticky-polyfill.js`

      var visibleFooterHeight = 0;
      var footerCell = table.querySelector('tfoot td');

      if (footerCell) {
        var footerCellY = footerCell.getBoundingClientRect().y;
        var overflowRect = el.getBoundingClientRect();
        var scale = el.offsetHeight / overflowRect.height;
        visibleFooterHeight = Math.min(el.clientHeight - scale * (footerCellY - overflowRect.y), el.clientHeight); // can be negative if sticky footers don't work in browser (e.g. Safari)

        visibleFooterHeight = Math.max(visibleFooterHeight, 0);
      }

      var footerRatio;

      if (overflowHeight > 0) {
        footerRatio = visibleFooterHeight / el.offsetHeight;
      }

      this.setProperties({
        scrollLeft: scrollLeft,
        scrollRight: scrollRight,
        scrollTop: scrollTop,
        scrollBottom: scrollBottom,
        scrollbarHeight: scrollbarHeight,
        scrollbarWidth: scrollbarWidth,
        overflowHeight: overflowHeight,
        overflowWidth: overflowWidth,
        tableHeight: tableHeight,
        tableWidth: tableWidth,
        headerHeight: headerHeight,
        visibleFooterHeight: visibleFooterHeight,
        footerRatio: footerRatio
      });
    },
    _updateListeners: function _updateListeners() {
      var hasIndicators = !Ember.isEmpty(this.get('enabledIndicators'));

      if (hasIndicators && !this._isListening) {
        this._addListeners();

        this._updateIndicators();
      } else if (!hasIndicators && this._isListening) {
        this._removeListeners();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._updateListeners();

      (0, _observer.addObserver)(this, 'enabledIndicators', this._updateListeners);
    },
    willDestroy: function willDestroy() {
      if (this._isListening) {
        this._removeListeners();
      }
    }
  });

  _exports.default = _default;
});