define("@nfn/cannon/components/site/cannon-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! This component is mainly needed to break the "flex" chain from site to navbar }}
  <header class="cannon-site__header" ...attributes>
    <div class="container">
      {{yield
        (hash navbar=(component "bs-navbar") brand=(component "site/cannon-brand"))
      }}
    </div>
  </header>
  */
  {
    "id": "pzZWZ0GQ",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"header\"],[24,0,\"cannon-site__header\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"navbar\",\"brand\"],[[30,[36,0],[\"bs-navbar\"],null],[30,[36,0],[\"site/cannon-brand\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/site/cannon-header.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});