define("ember-table/components/ember-thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0VoWAGoc",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[30,[36,2],null,[[\"cells\",\"isHeader\",\"row\"],[[32,1,[\"cells\"]],[32,1,[\"isHeader\"]],[30,[36,1],[\"ember-tr\"],[[\"api\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"api\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"ember-tr\",\"component\",\"hash\",\"if\",\"wrappedRows\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-table/components/ember-thead/template.hbs"
  });

  _exports.default = _default;
});