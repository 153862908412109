define("@nfn/cannon/components/hero-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="hero-header" ...attributes>
    <div class="hero-header__bg {{or @gradient "gradient-1"}}">
      <CannonPicture class="hero-header__picture" @src="hero1" />
    </div>
  
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="hero-header__outer">
            <div class="hero-header__inner">
              {{yield
                (hash
                  headline=(component "hero-header/headline")
                  subtitle=(component "hero-header/subtitle")
                  cta=(component "hero-header/cta")
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "c1zw92L4",
    "block": "{\"symbols\":[\"&attrs\",\"@gradient\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"hero-header\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"hero-header__bg \",[30,[36,0],[[32,2],\"gradient-1\"],null]]]],[12],[2,\"\\n    \"],[8,\"cannon-picture\",[[24,0,\"hero-header__picture\"]],[[\"@src\"],[\"hero1\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hero-header__outer\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"hero-header__inner\"],[12],[2,\"\\n            \"],[18,3,[[30,[36,2],null,[[\"headline\",\"subtitle\",\"cta\"],[[30,[36,1],[\"hero-header/headline\"],null],[30,[36,1],[\"hero-header/subtitle\"],null],[30,[36,1],[\"hero-header/cta\"],null]]]]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/hero-header.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});