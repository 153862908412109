define("@nfn/cannon/components/breadcrumbs/crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @active}}
    <li
      class="breadcrumb-item active"
      aria-current="page"
      data-test-breadcrumb="true"
      ...attributes
    >
      <Breadcrumbs::Inner @route={{@route}} @model={{@model}}>
        {{yield}}
      </Breadcrumbs::Inner>
    </li>
  {{else}}
    <li class="breadcrumb-item" data-test-breadcrumb="true" ...attributes>
      <Breadcrumbs::Inner @route={{@route}} @model={{@model}}>
        {{yield}}
      </Breadcrumbs::Inner>
    </li>
  {{/if}}
  */
  {
    "id": "OMP94Jsm",
    "block": "{\"symbols\":[\"&attrs\",\"@route\",\"@model\",\"&default\",\"@active\"],\"statements\":[[6,[37,0],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"li\"],[24,0,\"breadcrumb-item active\"],[24,\"aria-current\",\"page\"],[24,\"data-test-breadcrumb\",\"true\"],[17,1],[12],[2,\"\\n    \"],[8,\"breadcrumbs/inner\",[],[[\"@route\",\"@model\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,4,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"li\"],[24,0,\"breadcrumb-item\"],[24,\"data-test-breadcrumb\",\"true\"],[17,1],[12],[2,\"\\n    \"],[8,\"breadcrumbs/inner\",[],[[\"@route\",\"@model\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,4,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "@nfn/cannon/components/breadcrumbs/crumb.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});