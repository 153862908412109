define("@nfn/cannon/components/collapse-panel/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="card-header" id={{@id}} ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "4Kb7MR9O",
    "block": "{\"symbols\":[\"@id\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"card-header\"],[16,1,[32,1]],[17,2],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/collapse-panel/header.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});