define("ember-table/components/-private/scroll-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ccYVsWFF",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"scroll-indicator scroll-indicator__left\"],[15,5,[34,3]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"scroll-indicator scroll-indicator__right\"],[15,5,[34,2]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"scroll-indicator scroll-indicator__top\"],[15,5,[34,1]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"scroll-indicator scroll-indicator__bottom\"],[15,5,[34,0]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"bottomStyle\",\"topStyle\",\"rightStyle\",\"leftStyle\",\"showLeft\",\"if\",\"showRight\",\"showTop\",\"showBottom\"]}",
    "moduleName": "ember-table/components/-private/scroll-indicators/template.hbs"
  });

  _exports.default = _default;
});