define("@nfn/cannon/components/loading-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="loading-bar {{if @show "show"}}" ...attributes>
    <div class="progress" aria-hidden={{if @show "false" "true"}}>
      <div
        class="progress-bar progress-bar-striped progress-bar-animated active"
        role="progressbar"
        aria-valuetext="Loading"
      ></div>
    </div>
  </div>
  */
  {
    "id": "xNMqDCH3",
    "block": "{\"symbols\":[\"@show\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"loading-bar \",[30,[36,0],[[32,1],\"show\"],null]]]],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"progress\"],[15,\"aria-hidden\",[30,[36,0],[[32,1],\"false\",\"true\"],null]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"progress-bar progress-bar-striped progress-bar-animated active\"],[14,\"role\",\"progressbar\"],[14,\"aria-valuetext\",\"Loading\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "@nfn/cannon/components/loading-bar.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});