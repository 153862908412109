define("@nfn/cannon/components/site-dropdown/col-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h2 class="site-dropdown__col-head" ...attributes>
    {{yield}}
  </h2>
  */
  {
    "id": "jOJw5m29",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"h2\"],[24,0,\"site-dropdown__col-head\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/site-dropdown/col-head.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});