define("@nfn/cannon/components/awesome-table/total", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="awesome-table__total" ...attributes>
    <strong>
      Total
    </strong>
    <MetricVal data-test-count @value={{@total}} @type="number" @precision={{0}} />
  </div>
  */
  {
    "id": "37wYoyfw",
    "block": "{\"symbols\":[\"&attrs\",\"@total\"],\"statements\":[[11,\"div\"],[24,0,\"awesome-table__total\"],[17,1],[12],[2,\"\\n  \"],[10,\"strong\"],[12],[2,\"\\n    Total\\n  \"],[13],[2,\"\\n  \"],[8,\"metric-val\",[[24,\"data-test-count\",\"\"]],[[\"@value\",\"@type\",\"@precision\"],[[32,2],\"number\",0]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/awesome-table/total.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});