define("@nfn/cannon/components/bs-form/element/control/plaintextarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <textarea
    id={{@id}}
    disabled={{@disabled}}
    readonly
    aria-describedby={{@ariaDescribedBy}}
    value={{@value}}
    class="form-control-plaintext"
    ...attributes
  ></textarea>
  */
  {
    "id": "PkhgwLqG",
    "block": "{\"symbols\":[\"@id\",\"@disabled\",\"@ariaDescribedBy\",\"@value\",\"&attrs\"],\"statements\":[[11,\"textarea\"],[16,1,[32,1]],[16,\"disabled\",[32,2]],[24,\"readonly\",\"\"],[16,\"aria-describedby\",[32,3]],[16,2,[32,4]],[24,0,\"form-control-plaintext\"],[17,5],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/bs-form/element/control/plaintextarea.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});