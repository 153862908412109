define("@nfn/cannon/components/cannon-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    (hash
      header=(component "site/cannon-header")
      body=(component "site/cannon-body")
      footer=(component "site/cannon-footer")
    )
  }}
  */
  {
    "id": "nVcktaBe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"header\",\"body\",\"footer\"],[[30,[36,0],[\"site/cannon-header\"],null],[30,[36,0],[\"site/cannon-body\"],null],[30,[36,0],[\"site/cannon-footer\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/cannon-site.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});