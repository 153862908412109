define("ember-table/components/ember-th/sort-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2i5NY3sE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[\"et-sort-indicator \",[30,[36,2],[[35,4],\"is-ascending\",\"is-descending\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[35,3]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"et-sort-toggle et-speech-only\"],[12],[2,\"Toggle Sort\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"sortIndex\",\"isMultiSorted\",\"if\",\"columnMeta\",\"isSortedAsc\",\"isSorted\",\"isSortable\"]}",
    "moduleName": "ember-table/components/ember-th/sort-indicator/template.hbs"
  });

  _exports.default = _default;
});