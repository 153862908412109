define("@nfn/cannon/components/arrow-cta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @route}}
    {{! This fun code is because we can't pass model if model doesn't exist }}
    {{#let
      (if
        @model
        (component "link-to" route=@route query=@query model=@model)
        (component "link-to" route=@route query=@query)
      )
      as |Link|
    }}
      <Link class="arrow-cta" ...attributes>
        <span class="arrow-cta__text" aria-label={{@text}}>
          {{@text}}
        </span>
        <FaIcon @icon="long-arrow-alt-right" class="arrow-cta__icon" />
      </Link>
    {{/let}}
  {{else}}
    <a class="arrow-cta" href={{@href}} ...attributes>
      <span class="arrow-cta__text" aria-label={{@text}}>
        {{@text}}
      </span>
      <FaIcon @icon="long-arrow-alt-right" class="arrow-cta__icon" />
    </a>
  {{/if}}
  */
  {
    "id": "hPmaOlCa",
    "block": "{\"symbols\":[\"Link\",\"@href\",\"&attrs\",\"@text\",\"@query\",\"@route\",\"@model\"],\"statements\":[[6,[37,1],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,7],[30,[36,0],[\"link-to\"],[[\"route\",\"query\",\"model\"],[[32,6],[32,5],[32,7]]]],[30,[36,0],[\"link-to\"],[[\"route\",\"query\"],[[32,6],[32,5]]]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[[24,0,\"arrow-cta\"],[17,3]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"arrow-cta__text\"],[15,\"aria-label\",[32,4]],[12],[2,\"\\n        \"],[1,[32,4]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"arrow-cta__icon\"]],[[\"@icon\"],[\"long-arrow-alt-right\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"arrow-cta\"],[16,6,[32,2]],[17,3],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"arrow-cta__text\"],[15,\"aria-label\",[32,4]],[12],[2,\"\\n      \"],[1,[32,4]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"fa-icon\",[[24,0,\"arrow-cta__icon\"]],[[\"@icon\"],[\"long-arrow-alt-right\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"if\",\"let\"]}",
    "moduleName": "@nfn/cannon/components/arrow-cta.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});