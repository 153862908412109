define("@nfn/cannon/components/site/cannon-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class="cannon-site__footer footer-bar" ...attributes>
    <div class="container footer-bar__inner">
      {{yield
        (hash
          legal=(component "site/cannon-footer/legal")
          navbar=(component "bs-navbar" fluid=true)
        )
      }}
    </div>
  </footer>
  */
  {
    "id": "/Bxl9OJN",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"footer\"],[24,0,\"cannon-site__footer footer-bar\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container footer-bar__inner\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"legal\",\"navbar\"],[[30,[36,0],[\"site/cannon-footer/legal\"],null],[30,[36,0],[\"bs-navbar\"],[[\"fluid\"],[true]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/site/cannon-footer.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});