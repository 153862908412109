define("@nfn/cannon/components/data-table/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr class="data-table__row" role="row" ...attributes>
    <td role="cell" class="data-table__cellheading">
      {{@label}}
    </td>
    <td role="cell" class="data-table__cell">
      {{yield}}
    </td>
  </tr>
  */
  {
    "id": "CWBaCsGL",
    "block": "{\"symbols\":[\"&attrs\",\"@label\",\"&default\"],\"statements\":[[11,\"tr\"],[24,0,\"data-table__row\"],[24,\"role\",\"row\"],[17,1],[12],[2,\"\\n  \"],[10,\"td\"],[14,\"role\",\"cell\"],[14,0,\"data-table__cellheading\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,\"role\",\"cell\"],[14,0,\"data-table__cell\"],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/data-table/row.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});