define("@nfn/cannon/components/site-dropdown/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="site-dropdown__list" ...attributes>
    {{yield}}
  </ul>
  */
  {
    "id": "4rRo90T/",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"ul\"],[24,0,\"site-dropdown__list\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/site-dropdown/list.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});