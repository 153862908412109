define("@nfn/cannon/components/awesome-table/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsButton @type="link" class="awesome-table__button" ...attributes>
    {{yield}}
    <BsTooltip @title={{@title}} />
  </BsButton>
  */
  {
    "id": "oDL9MDW0",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@title\"],\"statements\":[[8,\"bs-button\",[[24,0,\"awesome-table__button\"],[17,1]],[[\"@type\"],[\"link\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,2,null],[2,\"\\n  \"],[8,\"bs-tooltip\",[],[[\"@title\"],[[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/awesome-table/button.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});