define("@nfn/cannon/components/awesome-table/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BasicDropdown
    class="awesome-table-settings"
    @renderInPlace={{true}}
    @verticalPosition="above"
    @buttonPosition="left"
    ...attributes
    as |dd|
  >
    <dd.Trigger class="awesome-table-settings__trigger btn btn-link">
      <FaIcon @icon="cog" />
      <BsTooltip @title="Table Settings" />
    </dd.Trigger>
    <dd.Content class="card awesome-table-settings__content">
      <div class="card-body">
        <h5 class="card-title">
          Table Settings
        </h5>
        <form action="#">
          {{#let @columnChooser as |ColumnChooser|}}
            <ColumnChooser />
          {{/let}}
          <BsButton @onClick={{@onReset}} @type="outline-secondary">
            Reset to defaults
          </BsButton>
        </form>
      </div>
    </dd.Content>
  </BasicDropdown>
  */
  {
    "id": "Zy64SFo5",
    "block": "{\"symbols\":[\"dd\",\"ColumnChooser\",\"&attrs\",\"@columnChooser\",\"@onReset\"],\"statements\":[[8,\"basic-dropdown\",[[24,0,\"awesome-table-settings\"],[17,3]],[[\"@renderInPlace\",\"@verticalPosition\",\"@buttonPosition\"],[true,\"above\",\"left\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Trigger\"]],[[24,0,\"awesome-table-settings__trigger btn btn-link\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"cog\"]],null],[2,\"\\n    \"],[8,\"bs-tooltip\",[],[[\"@title\"],[\"Table Settings\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Content\"]],[[24,0,\"card awesome-table-settings__content\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n      \"],[10,\"h5\"],[14,0,\"card-title\"],[12],[2,\"\\n        Table Settings\\n      \"],[13],[2,\"\\n      \"],[10,\"form\"],[14,\"action\",\"#\"],[12],[2,\"\\n\"],[6,[37,0],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,[32,2],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[32,5],\"outline-secondary\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Reset to defaults\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"let\"]}",
    "moduleName": "@nfn/cannon/components/awesome-table/settings.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});