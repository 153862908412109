define("@nfn/cannon/components/breadcrumbs/inner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @route}}
    {{#if @model}}
      <LinkTo @route={{@route}} @model={{@model}}>
        {{yield}}
      </LinkTo>
    {{else}}
      <LinkTo @route={{@route}}>
        {{yield}}
      </LinkTo>
    {{/if}}
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "dx4UtyTM",
    "block": "{\"symbols\":[\"&default\",\"@route\",\"@model\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "@nfn/cannon/components/breadcrumbs/inner.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});