define("@nfn/cannon/components/page-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash head=(component "page-layout/head") page=(component "page-layout/page"))}}
  */
  {
    "id": "UvqchvlJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"head\",\"page\"],[[30,[36,0],[\"page-layout/head\"],null],[30,[36,0],[\"page-layout/page\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/page-layout.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});