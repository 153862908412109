define("@nfn/cannon/components/data-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="data-table" ...attributes>
    {{yield
      (hash header=(component "data-table/header") table=(component "data-table/table"))
    }}
  </div>
  */
  {
    "id": "j8oRgAn+",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"data-table\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"header\",\"table\"],[[30,[36,0],[\"data-table/header\"],null],[30,[36,0],[\"data-table/table\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/data-table.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});