define("@nfn/cannon/components/data-table/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="data-table__table-container" ...attributes>
    <table class="data-table__table" role="table">
      <thead class="sr-only" role="rowgroup">
        <tr role="row">
          <th role="columnheader" scope="col">
            Label
          </th>
          <th role="columnheader" scope="col">
            Value
          </th>
        </tr>
      </thead>
      {{yield
        (hash row=(component "data-table/row") column=(component "data-table/column"))
      }}
    </table>
  </div>
  */
  {
    "id": "hS0e69BF",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"data-table__table-container\"],[17,1],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"data-table__table\"],[14,\"role\",\"table\"],[12],[2,\"\\n    \"],[10,\"thead\"],[14,0,\"sr-only\"],[14,\"role\",\"rowgroup\"],[12],[2,\"\\n      \"],[10,\"tr\"],[14,\"role\",\"row\"],[12],[2,\"\\n        \"],[10,\"th\"],[14,\"role\",\"columnheader\"],[14,\"scope\",\"col\"],[12],[2,\"\\n          Label\\n        \"],[13],[2,\"\\n        \"],[10,\"th\"],[14,\"role\",\"columnheader\"],[14,\"scope\",\"col\"],[12],[2,\"\\n          Value\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"row\",\"column\"],[[30,[36,0],[\"data-table/row\"],null],[30,[36,0],[\"data-table/column\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/data-table/table.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});