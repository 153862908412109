define('ember-cli-rollbar2/initializers/rollbar', ['exports', 'ember-cli-rollbar2/utils/rollbar'], function (exports, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var config = new _rollbar.RollbarConfig(application.resolveRegistration('config:environment'));
    if (config.rollbarConfig.enabled) {
      var instance = config.newInstance();
      application.register('rollbar:main', instance, { instantiate: false });
      if (config.addonConfig.captureEmberErrors) {
        (0, _rollbar.captureEmberErrors)(instance, config.addonConfig.outputEmberErrorsToConsole);
      }
      Ember.deprecate('captureEmberLogger is deprecated (along with Ember.Logger) and will be removed in a future release.', !config.addonConfig.captureEmberLogger, {
        id: 'RB-LOGGER-1',
        until: '1.0'
      });
      if (config.addonConfig.captureEmberLogger) {
        (0, _rollbar.captureEmberLogger)(instance);
      }
    }
  }

  exports.default = {
    initialize: initialize
  };
});