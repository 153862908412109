define("@nfn/cannon/components/content-link/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="content-link__image" ...attributes>
    <a href={{@href}}>
      <img class="content-link__image-img" src={{@src}} alt={{@alt}} />
    </a>
  </div>
  */
  {
    "id": "3E6IL0JY",
    "block": "{\"symbols\":[\"&attrs\",\"@href\",\"@src\",\"@alt\"],\"statements\":[[11,\"div\"],[24,0,\"content-link__image\"],[17,1],[12],[2,\"\\n  \"],[10,\"a\"],[15,6,[32,2]],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"content-link__image-img\"],[15,\"src\",[32,3]],[15,\"alt\",[32,4]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/content-link/image.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});