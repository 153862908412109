define("ember-table/components/ember-tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sE+l7EZZ",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&default\"],\"statements\":[[6,[37,13],[[30,[36,12],[[30,[36,12],[[35,11]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,10],null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"rowSelectionMode\",\"checkboxSelectionMode\"],[[32,1],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,[[30,[36,2],null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"row\"],[[32,2,[\"rowValue\"]],[32,2,[\"rowMeta\"]],[32,2,[\"cells\"]],[32,2,[\"rowSelectionMode\"]],[30,[36,1],[\"ember-tr\"],[[\"api\"],[[32,2]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"api\"],[[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"ember-tr\",\"component\",\"hash\",\"if\",\"checkboxSelectionMode\",\"rowSelectionMode\",\"canSelect\",\"rowMetaCache\",\"columnMetaCache\",\"columns\",\"-ember-table-private/row-wrapper\",\"wrappedRowArray\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-table/components/ember-tfoot/template.hbs"
  });

  _exports.default = _default;
});