define("@nfn/cannon/components/awesome-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="awesome-table" ...attributes>
    {{yield
      (hash
        head=(component "awesome-table/head")
        total=(component "awesome-table/total")
        button=(component "awesome-table/button")
      )
    }}
  </div>
  */
  {
    "id": "ZALfB4mR",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"awesome-table\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"head\",\"total\",\"button\"],[[30,[36,0],[\"awesome-table/head\"],null],[30,[36,0],[\"awesome-table/total\"],null],[30,[36,0],[\"awesome-table/button\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/awesome-table.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});