define("@nfn/cannon/components/site-dropdown/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let @dd.Trigger as |TheTrigger|}}
    <TheTrigger
      class="nav-link site-dropdown__trigger
        {{if @dd.isOpen "site-dropdown__trigger--open"}}"
      ...attributes
      {{on "mouseenter" @mouseEnter}}
      {{on "mouseleave" @mouseLeave}}
    >
      {{yield}}
    </TheTrigger>
  {{/let}}
  */
  {
    "id": "LelgdcI0",
    "block": "{\"symbols\":[\"TheTrigger\",\"@dd\",\"&attrs\",\"@mouseEnter\",\"@mouseLeave\",\"&default\"],\"statements\":[[6,[37,2],[[32,2,[\"Trigger\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1],[[16,0,[31,[\"nav-link site-dropdown__trigger\\n      \",[30,[36,0],[[32,2,[\"isOpen\"]],\"site-dropdown__trigger--open\"],null]]]],[17,3],[4,[38,1],[\"mouseenter\",[32,4]],null],[4,[38,1],[\"mouseleave\",[32,5]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,6,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"let\"]}",
    "moduleName": "@nfn/cannon/components/site-dropdown/trigger.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});