define("@nfn/cannon/components/key-controller/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsTooltip @placement={{@placement}} @visible={{@showKeyHints}} @triggerEvents="">
    {{@content}}
    {{yield}}
  </BsTooltip>
  */
  {
    "id": "crMNvJHe",
    "block": "{\"symbols\":[\"@placement\",\"@showKeyHints\",\"@content\",\"&default\"],\"statements\":[[8,\"bs-tooltip\",[],[[\"@placement\",\"@visible\",\"@triggerEvents\"],[[32,1],[32,2],\"\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,3]],[2,\"\\n  \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/key-controller/tooltip.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});