define('ember-cli-rollbar2/utils/rollbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.captureEmberErrors = captureEmberErrors;
  exports.captureEmberLogger = captureEmberLogger;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Logger = Ember.Logger;


  var CONFIG_DEFAULTS = {
    captureEmberErrors: true,
    outputEmberErrorsToConsole: true,
    captureEmberLogger: false,
    serverTokenEnv: undefined
  };

  var RollbarConfig = exports.RollbarConfig = function () {
    function RollbarConfig(env) {
      _classCallCheck(this, RollbarConfig);

      this.environment = env.environment;
      this._rollbarConfig = env.rollbar || {};
      this._rollbarConfig.enabled = this._calculateEnabled();
      this._addonConfig = Ember.assign({}, CONFIG_DEFAULTS, env['ember-cli-rollbar']);
    }

    _createClass(RollbarConfig, [{
      key: '_calculateEnabled',
      value: function _calculateEnabled() {
        var defaultEnabled = this.environment !== 'development' && this.environment !== 'test';
        var enabled = this.rollbarConfig.enabled;
        return typeof enabled !== 'undefined' ? enabled : defaultEnabled;
      }
    }, {
      key: '_getServerToken',


      /**
       * This allows you to specify the Rollbar server token in a process environment
       * variable, so it doesn't end up being leaked into the client Ember environment.
       */
      value: function _getServerToken() {
        if (this.addonConfig.serverTokenEnv && (typeof FastBoot === 'undefined' ? 'undefined' : _typeof(FastBoot)) !== undefined) {
          var process = FastBoot.require('process');
          return process.env[this.addonConfig.serverTokenEnv];
        } else {
          return this.addonConfig.serverToken;
        }
      }

      /**
       * Returns the configuration for the server-side of Rollbar.
       * Basically it is what is in the environment (and stored in window._rollbarConfig)
       * but replacing the client-side token with a server-side one.
       */

    }, {
      key: 'newInstance',


      /**
       * Gets an instance of Rollbar client, depending on whether or not you are in FastBoot.
       *
       * The Rollbar client acts like a single library, but it really is
       * two libraries mushed into one: one that works in Node and one that
       * works in the Browser. You can't conflate the two. In particular,
       * the Browser library can't work in Node, and the Node library can't work
       * in the browser. In addition, they both expect different access token types.
       *
       * In addition, due to the packaging limitations of the Rollbar client library,
       * we cannot support non-singletons in non-FastBoot versions.
       */
      value: function newInstance() {
        if (typeof FastBoot !== 'undefined') {
          var _Rollbar = FastBoot.require('rollbar');
          return new _Rollbar(this.serverConfig);
        } else {
          /* global Rollbar */
          return Rollbar;
        }
      }
    }, {
      key: 'rollbarConfig',
      get: function get() {
        return this._rollbarConfig;
      }
    }, {
      key: 'addonConfig',
      get: function get() {
        return this._addonConfig;
      }
    }, {
      key: 'serverConfig',
      get: function get() {
        return Ember.assign({}, this.rollbarConfig, {
          accessToken: this._getServerToken()
        });
      }
    }]);

    return RollbarConfig;
  }();

  // Copied from ember-metal
  function getStack(error) {
    var stack = error.stack,
        message = error.message;


    if (stack && stack.indexOf(message) === -1) {
      stack = message + '\n' + stack;
    }
    return stack;
  }

  /**
   * Wraps Ember.onerror with Rollbar. Returns the old value of onerror.
   */
  function captureEmberErrors(instance) {
    var outputToConsole = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    var previous = Ember.onerror;

    if (typeof FastBoot !== 'undefined') {
      // Fastboot's default error handler always prints to the console so we don't have to.
      outputToConsole = false;
    }

    if (outputToConsole) {
      /* eslint-disable no-console */
      var origError = console.error;
      Ember.onerror = function (err) {
        instance.error(err);
        origError(getStack(err));
      };
    } else {
      Ember.onerror = function (err) {
        instance.error(err);
      };
    }
    return previous;
  }

  function wrapLogger(instance, fn, method) {
    return function rollbarWrapper() {
      instance[method].apply(instance, arguments);
      fn.apply(this, arguments);
    };
  }

  /**
   * Wraps Ember.Logger methods in Rollbar.
   */
  function captureEmberLogger(instance) {
    Logger.error = wrapLogger(instance, Logger.error, 'error');
    Logger.warn = wrapLogger(instance, Logger.warn, 'warning');
    Logger.info = wrapLogger(instance, Logger.info, 'info');
    Logger.debug = wrapLogger(instance, Logger.debug, 'debug');
  }
});