define("ember-table/components/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xrGlMsx7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"ember-table-overflow\"],[15,1,[31,[[34,0],\"-overflow\"]]],[12],[2,\"\\n  \"],[10,\"table\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,3],null,[[\"api\",\"head\",\"body\",\"foot\"],[[35,1],[30,[36,2],[\"ember-thead\"],[[\"api\"],[[35,1]]]],[30,[36,2],[\"ember-tbody\"],[[\"api\"],[[35,1]]]],[30,[36,2],[\"ember-tfoot\"],[[\"api\"],[[35,1]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,4],null,[[\"api\"],[[35,1]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"elementId\",\"api\",\"component\",\"hash\",\"-ember-table-private/scroll-indicators\"]}",
    "moduleName": "ember-table/components/ember-table/template.hbs"
  });

  _exports.default = _default;
});