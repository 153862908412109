define("@nfn/cannon/components/cannon-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="custom-control custom-checkbox" ...attributes>
    <input
      type="checkbox"
      id={{@inputId}}
      checked={{@value}}
      class="custom-control-input"
      onchange={{@onChange}}
      aria-controls={{@ariaControls}}
      aria-expanded={{@ariaExpanded}}
      disabled={{@disabled}}
    />
    <label class="custom-control-label" for={{@inputId}}>
      {{yield}}
    </label>
  </div>
  */
  {
    "id": "ZNPpxtCK",
    "block": "{\"symbols\":[\"&attrs\",\"@inputId\",\"@value\",\"@onChange\",\"@ariaControls\",\"@ariaExpanded\",\"@disabled\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"custom-control custom-checkbox\"],[17,1],[12],[2,\"\\n  \"],[10,\"input\"],[15,1,[32,2]],[15,\"checked\",[32,3]],[14,0,\"custom-control-input\"],[15,\"onchange\",[32,4]],[15,\"aria-controls\",[32,5]],[15,\"aria-expanded\",[32,6]],[15,\"disabled\",[32,7]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n  \"],[10,\"label\"],[14,0,\"custom-control-label\"],[15,\"for\",[32,2]],[12],[2,\"\\n    \"],[18,8,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/cannon-checkbox.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});