define("@nfn/cannon/components/collapse-panel/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsCollapse
    ...attributes
    id={{@collapseId}}
    @collapsed={{not @open}}
    aria-labelledby={{@headerId}}
  >
    <div class="card-body">
      {{yield}}
    </div>
  </BsCollapse>
  */
  {
    "id": "uNuwzK8M",
    "block": "{\"symbols\":[\"&attrs\",\"@collapseId\",\"@open\",\"@headerId\",\"&default\"],\"statements\":[[8,\"bs-collapse\",[[17,1],[16,1,[32,2]],[16,\"aria-labelledby\",[32,4]]],[[\"@collapsed\"],[[30,[36,0],[[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[18,5,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\"]}",
    "moduleName": "@nfn/cannon/components/collapse-panel/body.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});