define("@nfn/cannon/components/content-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="content-link" ...attributes>
    <div class="content-link__wrapper">
      {{yield
        (hash
          image=(component "content-link/image" href=@href route=@route query=@query)
          content=(component "content-link/content" href=@href route=@route query=@query)
        )
      }}
    </div>
  </div>
  */
  {
    "id": "Ys447ysc",
    "block": "{\"symbols\":[\"&attrs\",\"@query\",\"@route\",\"@href\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"content-link\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content-link__wrapper\"],[12],[2,\"\\n    \"],[18,5,[[30,[36,1],null,[[\"image\",\"content\"],[[30,[36,0],[\"content-link/image\"],[[\"href\",\"route\",\"query\"],[[32,4],[32,3],[32,2]]]],[30,[36,0],[\"content-link/content\"],[[\"href\",\"route\",\"query\"],[[32,4],[32,3],[32,2]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "@nfn/cannon/components/content-link.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});