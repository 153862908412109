define("@nfn/cannon/components/hero-header/headline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h1 class="hero-header__headline type--headline" ...attributes>
    {{yield}}
  </h1>
  */
  {
    "id": "jWs+I6xG",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"h1\"],[24,0,\"hero-header__headline type--headline\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "@nfn/cannon/components/hero-header/headline.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});